import PropTypes from 'prop-types';
import useFooterSettings from '../use_footer_settings';

import SiteFooterView from './site_footer_view';

const SiteFooterContainer = ({
  className,
  tagName,
  isRegPath,
  isLoginPath,
}) => {
  const [footerSettings] = useFooterSettings();
  const { showRegPath, showOnLogin } = footerSettings;

  if (isLoginPath && !showOnLogin) return null;
  if (isRegPath && !showRegPath) return null;

  return (
    <SiteFooterView
      className={className}
      footerSettings={footerSettings}
      tagName={tagName}
    />
  );
};

SiteFooterContainer.propTypes = {
  className: PropTypes.string,
  tagName: PropTypes.string,
  isRegPath: PropTypes.bool,
  isLoginPath: PropTypes.bool,
};

SiteFooterContainer.defaultProps = {
  className: '',
  tagName: 'footer',
  isRegPath: false,
  isLoginPath: false,
};

export default SiteFooterContainer;
