import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { zendesk } from 'bv-services';
import { show } from '@intercom/messenger-js-sdk';
import classnames from 'classnames';
import { compose } from 'underscore';
import { t } from 'bv-i18n';
import { useFeature } from '../../hooks';
import liveChatStore, { fetchSettingsThunk } from './ducks';

const { addReducers } = window.reduxState;

addReducers({ liveChatStore });

const LiveChat = ({
  className, card, fetchSettings, settings, loaded, loading, location,
  showButton, text,
}) => {
  useEffect(() => {
    if (!loaded && !loading) fetchSettings();
  }, []);

  const [intercomEnabled, intercomResolved] = useFeature('intercom_live_chat');

  if (!loaded || !intercomResolved) return null;

  const locationSetting = settings.find((setting) => setting.location === location);
  if (locationSetting && locationSetting.enabled === false) return null;

  const openLiveChat = intercomEnabled ? show : zendesk.liveChat;

  return (
    <div className={classnames(className, {
      'bvs-card': !!card,
    })}
    >
      {
        text
          ? (<div onClick={openLiveChat}>{text}</div>)
          : t('javascript.quick_chat.need_help')
      }
      {showButton && (
        <span
          className="bvs-text-brand"
          onClick={openLiveChat}
        >
            {t('javascript.quick_chat.live_chat')}
        </span>
      )}
    </div>
  );
};

LiveChat.propTypes = {
  className: PropTypes.string,
  card: PropTypes.bool,
  fetchSettings: PropTypes.func.isRequired,
  settings: PropTypes.arrayOf(Object).isRequired,
  loaded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.string,
  text: PropTypes.string,
  showButton: PropTypes.bool,
};

LiveChat.defaultProps = {
  className: '',
  card: false,
  location: '',
  showButton: true,
  text: null,
};

const mapStateToProps = (state) => state.liveChatStore;

const mapDispatchToProps = (dispatch) => ({
  fetchSettings: compose(dispatch, fetchSettingsThunk),
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveChat);
