import { useEffect } from 'react';

const useEventListener = (listener) => {
  useEffect(() => {
    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, []);
};

export default useEventListener;
