/* eslint react/no-multi-comp:off, max-classes-per-file: off, */
import { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { requireScript } from 'bv';

class HlsThirdPartyPlayer extends PureComponent {
  constructor(props) {
    super(props);

    const { opts } = props;
    this.videoRef = opts.ref ? opts.ref : createRef();
    this.isComponentUnmounted = false;
  }

  async componentDidMount() {
    const { url, opts } = this.props;

    try {
      await requireScript(window.HLS_PLAYER_SCRIPT_PATH);

      if (this.isComponentUnmounted) return;

      const { Hls } = window;

      this.hls = new Hls(opts.hlsConfig);
      this.hls.loadSource(url);
      this.hls.attachMedia(this.videoRef.current);
      this.hls.on(Hls.Events.ERROR, (event, data) => {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              this.hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              this.hls.recoverMediaError();
              break;
            default:
              this.hls.destroy();
              break;
          }
        }
      });
    } catch (error) {
      //
    }
  }

  componentWillUnmount() {
    this.isComponentUnmounted = true;
    if (this.hls) {
      this.hls.stopLoad();
      this.hls.destroy();
    }
  }

  render() {
    const { opts } = this.props;
    return <video ref={this.videoRef} {...opts} />;
  }
}

HlsThirdPartyPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  opts: PropTypes.instanceOf(Object).isRequired,
};

export default HlsThirdPartyPlayer;
