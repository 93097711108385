import { createElement } from 'react';
import PropTypes from 'prop-types';
import Link from './link';

const ListRow = ({
  href,
  label,
  hasArrow,
  count,
  to,
  children,
}) => (
  <li className="row list">
    {(href || to) ? createElement(
      to ? Link : 'a',
      { href, to },
      <>
        {label || children}
        {count && <span className="coupon-header-total">{count}</span>}
        {hasArrow && <div className="bvs-icon is-arrow-right" />}
      </>,
    ) : (label || children)}
  </li>
);

ListRow.propTypes = {
  href: PropTypes.string,
  label: PropTypes.node.isRequired,
  hasArrow: PropTypes.bool,
  to: PropTypes.string,
  children: PropTypes.node,
  count: PropTypes.number,
};

ListRow.defaultProps = {
  href: null,
  hasArrow: null,
  to: null,
  children: null,
  count: null,
};

export default ListRow;
