import { useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SmallSpinner from '../small_spinner';

const DropDown = ({
  input, label, meta: { touched, error, asyncValidating }, options, includeBlank, disabled,
}) => {
  const dropDownRef = useRef(null);
  const dropDownInputRef = useRef(null);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const dropDownClassNames = classnames('bvs-form-element__dropdown', {
    error: error && touched,
    expanded: isOptionsOpen,
    disabled,
  });

  const handleOptionsOpen = () => {
    if (disabled) return;
    const outsideClickListener = (event) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setIsOptionsOpen(false);
        document.removeEventListener('click', outsideClickListener);
      }
    };
    if (isOptionsOpen) {
      document.removeEventListener('click', outsideClickListener);
    } else {
      document.addEventListener('click', outsideClickListener);
    }

    setIsOptionsOpen(!isOptionsOpen);
  };

  const handleOptionSelected = (event, value) => {
    input.onChange(value);
    setIsOptionsOpen(false);
  };

  const findSelectedOption = useMemo(
    () => options.find((element) => element.value === input.value),
    [options, input],
  );

  return (
    <div className="bvs-form-element" ref={dropDownRef}>
      {label && (
      <label htmlFor={dropDownInputRef} className="bvs-form-element__label">
        {label}
      </label>
      )}
      <div className={dropDownClassNames} onClick={handleOptionsOpen}>
        <span className="bvs-form-element__dropdown-value">
          {findSelectedOption ? findSelectedOption.label : (includeBlank || label)}
        </span>
        <span className="bvs-form-element__dropdown-chevron" />
        <input
          {...input}
          type="hidden"
          ref={dropDownInputRef}
        />
      </div>
      {isOptionsOpen && (
      <div className="bvs-form-element__dropdown-options">
        {includeBlank && (
        <div
          className={classnames('bvs-form-element__dropdown-option', {
            selected: !input.value,
          })}
        >
          <span>{includeBlank}</span>
        </div>
        )}
        {options.map(({ value, label: optionLabel }) => (
          <div
            key={value}
            className={classnames('bvs-form-element__dropdown-option', {
              selected: value === input.value,
            })}
            onClick={(event) => handleOptionSelected(event, value)}
          >
            <span>{optionLabel}</span>
          </div>
        ))}
      </div>
      )}
      {error && touched && <div className="bvs-form-element__hint-error">{error}</div>}
      {asyncValidating && <div className="account-form-validations-spinner"><SmallSpinner /></div>}
    </div>
  );
};

DropDown.propTypes = {
  includeBlank: PropTypes.string,
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string,
  meta: PropTypes.instanceOf(Object).isRequired,
  options: PropTypes.arrayOf(Object),
  disabled: PropTypes.bool,
};

DropDown.defaultProps = {
  includeBlank: '',
  label: '',
  options: [],
  disabled: false,
};

export default DropDown;
