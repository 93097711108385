import { Component } from 'react';
import { defaultCmsAssetPath } from 'bv';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Image from './image';

class List extends Component {
  constructor(props) {
    super(props);

    const { expanded } = this.props;

    this.state = { expanded };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    const { expandable } = this.props;
    const { expanded } = this.state;

    if (!expandable) {
      return;
    }

    this.setState({
      expanded: !expanded,
    });
  }

  render() {
    const {
      expandable, hasHeader, header, children, logo,
    } = this.props;

    const { expanded } = this.state;

    const className = classnames('list-wrapper', {
      expandable,
      open: expanded,
    });

    const arrowIconClassName = classnames('bvs-icon', {
      'is-arrow-up': expanded,
      'is-arrow-down': !expanded,
    });

    return (
      <div className={className}>
        {hasHeader && (
          <div className="list-header bvs-link" onClick={this.toggle}>
            {logo && <Image width="24" className="sportsbook-v2-country__logo" imageAlt={header} src={defaultCmsAssetPath(logo)} />}
            <h3>{header}</h3>
            {
              expandable && (
                <div className="list-header__icons">
                  <span className={arrowIconClassName} />
                </div>
              )
            }
          </div>
        )}
        { (!expandable || expanded) && (
          <ul className="bvs-card">
            { children }
          </ul>
        ) }
      </div>
    );
  }
}

List.propTypes = {
  header: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  expanded: PropTypes.bool,
  expandable: PropTypes.bool,
  hasHeader: PropTypes.bool,
  logo: PropTypes.string,
};

List.defaultProps = {
  expanded: false,
  expandable: false,
  hasHeader: true,
  logo: null,
};

export default List;
