import PropTypes from 'prop-types';
import { useRef } from 'react';
import { sanitize } from 'dompurify';

const Checkbox = ({
  input, title, description, id, disabled,
}) => {
  const checkboxRef = useRef(null);

  return (
    <div className="bvs-form-element">
      <div className="bvs-form-element__checkbox">
        <label htmlFor={id} className="bvs-form-element__checkbox-tick">
          <input
            {...input}
            id={id}
            ref={checkboxRef}
            disabled={disabled}
          />
          <div className="checkmark" />
        </label>
        <div className="bvs-form-element__checkbox-content">
          <h3 className="bvs-form-element__checkbox-title">{title}</h3>
          <p
            dangerouslySetInnerHTML={{
              __html: sanitize(description),
            }}
          />
        </div>
      </div>
    </div>
  );
};

Checkbox.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.string,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  id: `checkbox${Math.random()}`,
  disabled: false,
};

export default Checkbox;
