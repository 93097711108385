import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import NativePlayer from './native';
import HlsThirdPartyPlayer from './hls_third_party_player';

const HLS_EXTENSIONS = /\.(m3u8)($|\?)/i;
const HTTP_PROTOCOL = /http:/;

const HlsPlayer = ({ opts, url }) => {
  const [canUseNative, setCanUseNative] = useState();
  const testVideoRef = useRef(null);

  useEffect(() => {
    if (!testVideoRef.current) return;
    setCanUseNative((HTTP_PROTOCOL.test(url)
      || testVideoRef.current.canPlayType('application/vnd.apple.mpegurl')));
  }, [url]);

  return (
    <>
      <video ref={testVideoRef} style={{ display: 'none' }} />
      {canUseNative !== undefined && (canUseNative
        ? <NativePlayer url={url} opts={opts} />
        : <HlsThirdPartyPlayer url={url} opts={opts} />
      )}
    </>
  );
};

HlsPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  opts: PropTypes.instanceOf(Object).isRequired,
};

HlsPlayer.typeName = 'HlsPlayer';
HlsPlayer.canPlay = (url) => HLS_EXTENSIONS.test(url);

export default HlsPlayer;
