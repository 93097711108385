import { useDispatch, useSelector } from 'react-redux';
import { selectLoggedIn } from 'session-selectors';
import { locale as getLocale } from 'bv-i18n';
import { useEffect } from 'react';
import { fetchFooterSettings } from './duck';
import { getSiteFooter, getHasLoadedFooterSettings } from './selectors';

const useFooterSettings = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(selectLoggedIn);
  const locale = getLocale();
  const footerSettings = useSelector(getSiteFooter);
  const hasLoadedFooterSettings = useSelector(getHasLoadedFooterSettings);

  useEffect(() => {
    if (!hasLoadedFooterSettings) {
      dispatch(fetchFooterSettings(loggedIn, locale));
    }
  }, [loggedIn, locale, hasLoadedFooterSettings]);

  return [footerSettings, hasLoadedFooterSettings];
};

export default useFooterSettings;
