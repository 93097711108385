import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const OnOff = forwardRef((props, ref) => {
  const { inputId, className, ...rest } = props;

  return (
    <span className={classnames('onoffswitch', className)}>
      <label className="onoffswitch-label" htmlFor={inputId}>
        <input
          type="checkbox"
          className="onoffswitch-checkbox"
          style={{ display: 'none' }}
          id={inputId}
          ref={ref}
          {...rest}
        />
        <span className="onoffswitch-inner" />
        <span className="onoffswitch-switch" />
      </label>
    </span>
  );
});

OnOff.propTypes = {
  className: PropTypes.string.isRequired,
  inputId: PropTypes.string.isRequired,
};

export default OnOff;
