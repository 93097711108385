import { t } from 'bv-i18n';

export const CARD_STATUSES = {
  new: 'NEW',
  pending: 'PENDING',
  completed: 'COMPLETED',
};

export const RESTRICTIONS_TRANSLATIONS_TOKENS = [
  'kyc.restrictions.withdraw',
  'kyc.restrictions.deposit',
  'kyc.restrictions.place_sports_bets',
  'kyc.restrictions.cash_out',
  'kyc.restrictions.play_casino',
  'kyc.restrictions.opt_in_to_offers',
];

export const getLabel = ({ integrationType, name }, label) => {
  if (integrationType === 'IFRAME') {
    return t(`javascript.kyc.provider_cta.${name.toLowerCase()}`);
  }
  return label;
};
