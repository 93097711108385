import api from './api';

const SET = 'siteFooter/SET_FOOTER_SETTINGS';

export const setFooterSettings = (footerSettings) => ({
  type: SET,
  footerSettings,
});

export const fetchFooterSettings = (loggedIn, locale) => (dispatch) => {
  api(loggedIn, locale).then((response) => {
    dispatch(setFooterSettings(response));
  });
};

const initialState = {
  footerLinks: [],
  logos: [],
  partnerLogos: [],
  trustPilotConfig: {
    enabled: false,
  },
  snippet: null,
  numberLogosFirstRowMobile: null,
  showLogo: false,
  showPartners: false,
  showRegPath: false,
  showOnLogin: false,
  hasLoadedFooterSettings: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET:
      return {
        ...state,
        hasLoadedFooterSettings: true,
        ...action.footerSettings,
      };
    default:
      return state;
  }
};
