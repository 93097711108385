import PropTypes from 'prop-types';

import { showTopLevelNavigation } from 'global-config';
import { useSeoEnabled } from 'Seo/helpers';
import { withErrorBoundary } from 'bv-error-boundary';
import withLazyLoad from 'bv-lazy-load';
import TabsMenu from './tabs_menu';
import ErrorBoundaryFallback from './error_boundary_fallback';

const LazySeo = withLazyLoad()('seo');

const ContentLayout = ({
  showTabs, seoVisible, children, fetching,
}) => {
  const seoEnabled = useSeoEnabled();

  return (
    <div className="react-contents">
      {showTabs && showTopLevelNavigation && <TabsMenu />}
      {children}
      {seoEnabled && seoVisible && !fetching && <LazySeo />}
    </div>
  );
};

ContentLayout.propTypes = {
  showTabs: PropTypes.bool,
  seoVisible: PropTypes.bool,
  fetching: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

ContentLayout.defaultProps = {
  showTabs: false,
  seoVisible: true,
  fetching: false,
};

export default withErrorBoundary(ContentLayout, {
  FallbackComponent: ErrorBoundaryFallback,
});
