import { useEffect, useState } from 'react';
import { getSportNewHomeStyle } from 'bv-services/sports';

const initialState = { newStyle: false, loading: true };

export default ({ sportId }) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (sportId) {
      getSportNewHomeStyle(parseInt(sportId, 10)).then((newStyle) => {
        setState({ loading: false, newStyle });
      });
    }
    return () => {
      if (sportId) setState(initialState);
    };
  }, [sportId]);

  return [state.newStyle, state.loading];
};
