import PropTypes from 'prop-types';
import { defaultCmsAssetPath } from 'bv';
// relative paths avoid circular dependencies in jest
import Icon from '../icon';
import Image from '../image';

const SeoTitleView = ({
  tag, heading, setHeaderRef, logo, logoAlt, tagClassName, textClassName, iconProps, onClick,
}) => {
  const Tag = tag;

  return (
    <div id="site-header-title" className="site-header-title" ref={setHeaderRef}>
      <Tag className={`bvs-header bvs-ellipsis bvs-h4 ${tagClassName}`} onClick={onClick}>
        {logo && <Image width="24" className="sportsbook-v2-sport__logo" imageAlt={logoAlt || heading} src={defaultCmsAssetPath(logo)} />}
        {textClassName ? <span className={textClassName}>{heading}</span> : heading}
        {iconProps && <Icon {...iconProps} />}
      </Tag>
    </div>
  );
};

SeoTitleView.propTypes = {
  tag: PropTypes.string,
  heading: PropTypes.string.isRequired,
  setHeaderRef: PropTypes.func,
  logo: PropTypes.string,
  logoAlt: PropTypes.string,
  tagClassName: PropTypes.string,
  textClassName: PropTypes.string,
  iconProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    inverted: PropTypes.bool,
  }),
  onClick: PropTypes.func,
};

SeoTitleView.defaultProps = {
  setHeaderRef: null,
  tag: 'h1',
  logo: null,
  logoAlt: '',
  iconProps: null,
  tagClassName: '',
  textClassName: '',
  onClick: () => {},
};

export default SeoTitleView;
