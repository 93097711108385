import { getJSON } from 'bv-fetch';
import { locale } from './i18n';

let getPromise;

const fetchSports = () => getJSON(`/api/sports?l=${locale()}`);

const getSports = () => {
  getPromise = getPromise || fetchSports();

  return getPromise;
};

const getSportByEventPathId = (sportEventPathId) => (
  getSports()
    .then((sports) => sports.find((sport) => sport.event_path_id === sportEventPathId))
);

const getSportBySportId = (sportId) => (
  getSports().then((sports) => sports.find((sport) => sport.id === sportId))
);

const getSportDescription = (sportEventPathId) => (
  getSportByEventPathId(sportEventPathId).then((sport) => (
    sport ? sport.description : undefined
  ))
);

const getSportNewHomeStyle = (sportEventPathId) => (
  getSportByEventPathId(sportEventPathId).then((sport) => (
    sport ? sport.new_home_style : false
  ))
);

const getSportIdFromEventPathId = (sportEventPathId) => (
  getSportByEventPathId(sportEventPathId).then((sport) => (
    sport ? sport.id : undefined
  ))
);

export {
  getSportByEventPathId,
  getSportDescription,
  getSportIdFromEventPathId,
  getSportBySportId,
  getSportNewHomeStyle,
};
