import { createSelector } from 'reselect';
import { sortBy, omit } from 'underscore';
import { t } from 'bv-i18n';

const getKycStrategiesV2 = (state) => state.session.kycStrategiesV2;

export const anyKycStrategiesV2 = createSelector(getKycStrategiesV2,
  (strategies) => Boolean(strategies.length));

const getOrderedKycStrategiesV2 = createSelector(
  getKycStrategiesV2,
  (strategies) => sortBy(strategies, (strategy) => strategy.order),
);

const getNotFinishedStrategiesV2 = createSelector(
  getKycStrategiesV2,
  (strategies) => strategies.filter((strategy) => strategy.status !== 'COMPLETED'),
);

export const getNotFinishedKycStrategiesV2Ids = createSelector(
  getNotFinishedStrategiesV2,
  (strategies) => strategies.map((strategy) => strategy.kycStrategyId),
);

export const composeKycStrategiesV2 = createSelector(
  getOrderedKycStrategiesV2,
  (strategies) => strategies.map((strategy) => ({
    title: t(`kycstrategy.${strategy.kycStrategyId}.card.title`),
    description: t(`kycstrategy.${strategy.kycStrategyId}.card.description`),
    button: t(`kycstrategy.${strategy.kycStrategyId}.card.button`),
    ...omit(strategy, 'errorCodes'),
  })),
);
