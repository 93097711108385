import { useSelector } from 'react-redux';
import { showKycBanner as showKycBannerConfig } from 'global-config';
import { useMemo } from 'react';
import { composeRestrictions } from 'VerificationZone/selectors/restrictions';
import { anyKycStrategiesV2 } from 'VerificationZone/selectors/strategies';

export default () => {
  const restrictions = useSelector(composeRestrictions);
  const anyKycStrategies = useSelector(anyKycStrategiesV2);

  const showBanner = useMemo(() => {
    const canWithdraw = restrictions[0]?.canPerformAction;
    const otherRestrictions = restrictions.slice(1);
    const anyOtherRestrictions = otherRestrictions.some(
      (restriction) => !restriction.canPerformAction);

    return (
      (!canWithdraw && showKycBannerConfig && anyKycStrategies)
        || (anyOtherRestrictions && anyKycStrategies));
  }, [restrictions, showKycBannerConfig, anyKycStrategies]);

  return { showKycBanner: showBanner };
};
