import { isInComponentsIframe } from '../../embedded';

export default () => ({
  handler: isInComponentsIframe() ? 'openNewTab' : 'relativeUrl',
  data: ({ productPage, inPlayUpcomingInterval, sportId }) => ({
    SPORTS: '/sports',
    INPLAY: `/live${sportId ? `/${sportId}` : '/overview'}`,
    UPCOMING: `/live${sportId ? `/${sportId}` : ''}/upcoming${inPlayUpcomingInterval ? `?${inPlayUpcomingInterval}` : ''}`,
    VIRTUALSPORTS: '/virtuals',
    LOTTERY: '/lottery',
    LIVECASINO: '/live-casino',
  }[productPage] || '/casino'),
});
