import { useState } from 'react';

// This hook is necessary for cases of mutlimodals opened - when visible modal has closed,
// it helps to return visibility for previous visible modal.

export default () => {
  const [hasOtherModals] = useState(document.body.classList.contains('has-modal'));

  const restoreHasModal = (allow = true) => {
    if (allow && hasOtherModals) {
      setTimeout(() => {
        document.body.classList.add('has-modal');
      }, 100);
    }
  };

  return {
    hasOtherModals,
    restoreHasModal,
  };
};
